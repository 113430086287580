<template>
    <div class="koutu-upload">
        <div class="wrap">
            <div class="title">{{ content.title }}</div>
            <div class="content">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <el-upload class="pic-uploader"
                       :action="''"          
                       :auto-upload="false"    
                       :show-file-list="false"   
                       :on-change="handleAvatarChangeIcon">
                
                <div class="uploadBtn"><i class="el-icon-plus"></i><span>{{ content.btnText }}</span></div>
                </el-upload>

                <ul>
                    <li v-for="line in content.desList" :key="line">
                        {{ line }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Koutu_Upload',
    props: {
      content: {
        type: Object,
        required: true,
        default: () => { }
      }
    },
    data() {
      return {
        imageUrl: ''
      }
    },
    methods: {
        handleAvatarChangeIcon(file) {//选中文件触发的change事件
        // console.log(file)
        const isJPG = file.raw.type === 'image/jpeg'
        const isPNG = file.raw.type === 'image/png'
        // const isLt2M = file.raw.size / 1024 / 1024 < 0.5  //限制上传文件的大小
        if (!isPNG && !isJPG) {
          this.$message.error('Upload images can only be in JPG/PNG format!')
          return false
        } else {
          this.imageUrl = URL.createObjectURL(file.raw);//赋值图片的url，用于图片回显功能
        }
      }

    }
  }
  </script>
  
  <style scoped lang="scss">
@media #{'only screen and (min-width: 992px)'} {
    .koutu-upload {
      background: var(--theme-primary-koutu-upload-bg);
      font-family: Arial, Arial;
      .wrap {
        max-width: 900px;
        margin: 0 auto;
        padding: 40px 0 100px;
        .title {
            font-size: 60px;
            font-weight: 900;
            color: var(--theme-primary-black);
            line-height: 1.3;
            text-align: center;
            width: 100%;
            margin: 0 auto 40px;
        }
        .content {
            background-color: white;
            padding: 50px 100px;
            border-radius: 36px;
            ul{
                li{
                    font-size: 20px;
                    line-height: 2;
                    color: #4C484C;
                    &::marker{
                        color: #D9D9D9;
                    }
                }
            }
            .avatar{
                margin: 0 auto;
                display: block;
                padding: 20px 0;
                max-width: 50%;
            }
            .pic-uploader {
                text-align: center;
                margin: 20px 0 50px;
                .uploadBtn {
                    padding: 20px 50px;
                    font-size: 26px;
                    display: flex;
                    align-items: center;
                    color: white;
                    font-weight: bold;
                    box-shadow: 0px 5px 25px 0px rgba(0,0,0,0.2);
                    background: var(--theme-primary);
                    border-radius: 40px;
                    span {
                        margin-left: 10px;
                    }
                }
            }

        }
      }
    }
  }
  @media #{'only screen and (max-width: 991px)'} {
    .koutu-upload {
      background: var(--theme-primary-koutu-upload-bg);
      font-family: Arial, Arial;
      .wrap {
        width: 100%;
        margin: 0 auto;
        padding: 1rem 0;
        .title {
            font-size: .48rem;
            font-weight: 900;
            color: var(--theme-primary-black);
            line-height: 1.3;
            text-align: center;
            width: 100%;
            margin: 0 auto 1rem;
        }
        .content {
            background-color: white;
            border-radius: .5rem;
            padding: .5rem 0;
            margin: 0 .3rem;
            ul{
                padding: 0 .6rem;
                li{
                    font-size: .3rem;
                    line-height: 2;
                    color: #4C484C;
                    &::marker{
                        color: #D9D9D9;
                    }
                }
            }
            .avatar{
                margin: 0 auto;
                display: block;
                padding: .5rem 0;
                max-width: 80%;
            }
            .pic-uploader {
                text-align: center;
                margin: .5rem 0;
                .uploadBtn {
                    padding: .25rem 1rem;
                    font-size: .3rem;
                    display: flex;
                    align-items: center;
                    color: white;
                    font-weight: bold;
                    box-shadow: 0px 5px 25px 0px rgba(0,0,0,0.2);
                    background: var(--theme-primary);
                    border-radius: .8rem;
                    span {
                        margin-left: .2rem;
                    }
                }
            }

        }
      }
    }
  }
  </style>