<template>
  <utilsRegister />
</template>
<script>
export default {
  components: { },
  name: "register",
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>