<template>
  <div class="advantages-wrapper" id="ADVANTAGE">
    <div class="content">
      <template v-if="type === 'default'">
        <div class="title-logo">
          <p class="font">{{ content.title }}</p>
          <p class="des">{{ content.des }}</p>
          <button class="left_btn" @click="scrollLocate">{{ content.btnText }}</button>
        </div>

        <ul class="list">
          <li class="item" v-for="(item, idx) in content.items" :key="idx">
            <!-- <img :src="getimg(item.icon)" /> -->
            <p class="title">{{ item.title }}</p>
            <div class="des">{{ item.des }}</div>
          </li>
        </ul>
      </template>
      <template v-else>
        <div class="sub-title-logo">
          <p class="font">{{ content.title }}</p>
          <p class="des">{{ content.des }}</p>
          <button class="left_btn" @click="scrollLocate">{{ content.btnText }}</button>
        </div>

        <ul class="sub-list">
          <li class="item" v-for="(item, idx) in content.items" :key="idx">
            <!-- <img :src="getimg(item.icon)" /> -->
            <p class="title">{{ item.title }}</p>
            <div class="des">{{ item.des }}</div>
          </li>
        </ul>
      </template>
    </div>

  </div>
</template>

<script>
export default {
  name: "advantage",
  props: {
    content: {
      type: Object,
      default: () => {
        return {

        }
      }
    },
    type: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {

    }
  },
  methods: {
    // getimg(img) {
    //   return require(`@/static/images/${img}`)
    // },
    // 滚动到定位手机位置
    scrollLocate() {
      document.querySelector(`#LOCATE`).scrollIntoView({
        block: 'end',
        behavior: 'smooth',
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: 981px) {
  .advantages-wrapper {
    display: flex;
    width: 100%;
    background: #081125;
    padding: 100px 0;
    .content {
      position: relative;
      width: 1240px;
      margin: 0 auto;
      display: flex;
    }


    .title-logo {
      flex: 0 0 444px;
      font-size: 40px;
      font-family: Manrope-Bold, Manrope;
      font-weight: bold;
      color: #fff;
      line-height: 55px;
      margin-right: 100px;

      .des {
        font-size: 16px;
        font-family: Manrope-Regular, Manrope;
        font-weight: 400;
        line-height: 22px;
        margin-top: 20px;
        margin-bottom: 64px;
      }

      .left_btn {
        min-width: 200px;
        padding: 18px 46px;
        box-sizing: border-box;
        border-radius: 4px;
        background: #24ADFE;
        color: #FFF;
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        white-space: nowrap;
        cursor: pointer;
      }
    }

    .sub-title-logo {
      flex: 0 0 400px;
      font-size: 40px;
      font-family: Manrope-Bold, Manrope;
      font-weight: bold;
      color: #fff;
      line-height: 55px;
      margin-right: 100px;

      .des {
        font-size: 16px;
        font-family: Manrope-Regular, Manrope;
        font-weight: 400;
        line-height: 22px;
        margin-top: 20px;
        margin-bottom: 64px;
      }

      .left_btn {
        min-width: 200px;
        padding: 18px 46px;
        box-sizing: border-box;
        border-radius: 4px;
        background: #24ADFE;
        color: #FFF;
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        white-space: nowrap;
        cursor: pointer;
      }
    }

    .list {
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 276px;

        &:first-child {
          margin-bottom: 86px;
        }

        &:nth-child(odd) {
          margin-right: 100px;
        }

        img {
          width: 48px;
          margin-bottom: 12px;
        }

        .title {
          font-size: 24px;
          font-family: Manrope-Bold, Manrope;
          font-weight: bold;
          color: #fff;
          line-height: 33px;
          margin-bottom: 18px;
        }

        .des {
          font-size: 16px;
          font-family: Manrope-Regular, Manrope;
          font-weight: 400;
          color: var(--Font-Wh2, rgba(255, 255, 255, 0.55));
          line-height: 22px;
        }
      }
    }

    .sub-list{
      display: flex;
      align-items: flex-start;
      gap: 40px;

      .item {
        width: 218px;

        img {
          width: 48px;
          margin-bottom: 12px;
        }

        .title {
          font-size: 24px;
          font-family: Manrope-Bold, Manrope;
          font-weight: bold;
          color: #fff;
          line-height: 33px;
          margin-bottom: 18px;
        }

        .des {
          font-size: 16px;
          font-family: Manrope-Regular, Manrope;
          font-weight: 400;
          color: var(--Font-Wh2, rgba(255, 255, 255, 0.55));
          line-height: 22px;
        }
      }
    }
  }
}

@media (max-width: 980px) {

  .advantages-wrapper {
    background: #081125;
    text-align: center;

    .content {
      padding: 1.35rem 0.32rem 1rem 0.32rem;
    }

    .title-logo {
      margin-bottom: 0.8rem;

      .font {
        font-size: 0.48rem;
        font-family: Manrope-Bold, Manrope;
        font-weight: bold;
        color: #fff;
        line-height: 0.65rem;
        margin-bottom: 0.24rem;
      }

      .des {
        font-size: 0.3rem;
        font-family: Manrope-Regular, Manrope;
        font-weight: 400;
        color: #fff;
        line-height: 0.44rem;
      }

      .left_btn {
        display: none;
      }
    }

    .sub-title-logo {
      margin-bottom: 0.8rem;

      .font {
        font-size: 0.48rem;
        font-family: Manrope-Bold, Manrope;
        font-weight: bold;
        color: #fff;
        line-height: 0.65rem;
        margin-bottom: 0.24rem;
      }

      .des {
        font-size: 0.3rem;
        font-family: Manrope-Regular, Manrope;
        font-weight: 400;
        color: #fff;
        line-height: 0.44rem;
      }

      .left_btn {
        display: none;
      }
    }

    .list {
      .item {
        margin-bottom: 0.64rem;

        &:last-child {
          margin-bottom: 0;
        }

        img {
          width: 0.96rem;
          margin-bottom: 0.3rem;
        }

        .title {
          font-size: 0.36rem;
          font-family: Manrope-Bold, Manrope;
          font-weight: bold;
          color: #fff;
          line-height: 0.49rem;
          margin-bottom: 0.16rem;
        }

        .des {
          font-size: 0.3rem;
          font-family: Manrope-Regular, Manrope;
          font-weight: 400;
          color: #fff;
          line-height: 0.42rem;
        }
      }
    }

    .sub-list {
      .item {
        margin-bottom: 0.64rem;

        &:last-child {
          margin-bottom: 0;
        }

        img {
          width: 0.96rem;
          margin-bottom: 0.3rem;
        }

        .title {
          font-size: 0.36rem;
          font-family: Manrope-Bold, Manrope;
          font-weight: bold;
          color: #fff;
          line-height: 0.49rem;
          margin-bottom: 0.16rem;
        }

        .des {
          font-size: 0.3rem;
          font-family: Manrope-Regular, Manrope;
          font-weight: 400;
          color: #fff;
          line-height: 0.42rem;
        }
      }
    }
  }
}
</style>