<template>
  <div>
    <div class="pay-wrap">
      <div class="container">
        <template v-if="loading">
          <h1>Fetching payment status...</h1>
          <div class="loader"></div>
          <p>
            If you have already paid, please do not close the current page. The
            request for payment information may be delayed for a few minutes,
            please be patient.
          </p>
        </template>
        <div class="pay-result" v-else>
          <h1>{{ content[status].title }}</h1>
          <p class="font-regular" v-html="successDes"></p>
          <div>
            <button class="submit-btn" @click="handleSubmit">
              {{ content[status].buttonTxt }}
            </button>
          </div>
          <div class="submit-home">
            <a :href="$origin('/')" class="font-bold tip" v-if="content[status].tip">
              {{ content[status].tip }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { checkOrder } from "@/api/pay";

export default {
  name: "Index",
  props: {
    content: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      ing: 10,
      timer: null,
      status: "pending",
      loading: true,
      outTime: 60,
      currentUserInfo: {
        email: "",
        token: "",
      },
      //  支付使用的邮箱
      payEmail: "",
      site: "",
    };
  },
  computed: {
    successDes() {
      return this.content[this.status].des.replace(
        "userEmail",
        this.currentUserInfo.email
      );
    },
  },
  mounted() {
    this.polling(1000);
  },
  methods: {
    polling(time) {
      this.timer = setInterval(() => {
        //  超时处理
        if (this.outTime === 0) {
          clearInterval(this.timer);
          this.status = "pending";
          this.loading = false;
          return;
        }
        if (this.ing < 90) {
          let random = Math.floor(Math.random() * 5) || 3;
          let num = this.ing + random;
          this.triggerProgress(num);
        }
        this.outTime--;
        this.handleCheckOrder();
      }, time);
    },
    triggerProgress(num) {
      this.ing = num;
      if (!this.$refs.progress) return;
      this.$refs.progress.style.width = `${this.ing}%`;
    },
    handleCheckOrder() {
      const transparentExtend = JSON.parse(
        this.$route.query.transparentExtend || "{}"
      );
      const data = {
        id: transparentExtend.retrieveCode,
      };
      checkOrder(data)
        .then((result) => {
          //  假如不为0，且进度条到了100（避免重复触发）说明支付成功
          if (result.data.status !== 0 && this.ing !== 100) {
            clearInterval(this.timer);
            this.triggerProgress(100);
            this.status = "success";
            this.currentUserInfo = result.data;
            this.loading = false;
          } else if (result.data.status === 0 && this.ing === 100) {
            clearInterval(this.timer);
            this.triggerProgress(100);
            this.loading = false;
            this.status = "fail";
          }
        })
        .catch((e) => {
          clearInterval(this.timer);
          this.status = "pending";
          this.loading = false;
        });
    },
    handleSubmit() {
      if (this.currentUserInfo.email) {
        this.$store.commit("OPEN_LOGIN", true);
      } else {
        window.location.href = this.$origin('/');
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss">
@media (min-width: 980px) {
  .pay-wrap {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    margin: 0;
    .container {
      text-align: center;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      max-width: 400px;
    }
    h1 {
      padding: 10px 0;
      color: #4caf50;
    }
    p {
      color: #333;
    }
    .submit-home {
      margin-top: 10px;
    }
    .submit-btn {
      background: #4caf50;
      color: #fff;
      width: 230px;
      padding: 12px 0;
      border: none;
      cursor: pointer;
      margin-top: 20px;
      &:hover {
        background: #4caf40;
      }
      border-radius: 8px;
    }
    .loader {
      border: 8px solid #f3f3f3;
      border-top: 8px solid #4caf50;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      animation: spin 2s linear infinite;
      margin: 20px auto;
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

@media (max-width: 980px) {
  .pay-wrap {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    margin: 0;
    .container {
      text-align: center;
      padding: 0.4rem;
      border-radius: 0.08rem;
      box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.1);
      background-color: #fff;
      max-width: 4rem;
      * {
        font-size: 0.2rem;
      }
    }
    h1 {
      padding: 0.2rem 0;
      color: #4caf50;
    }
    p {
      color: #333;
    }
    .submit-home {
      margin-top: 10px;
    }
    .submit-btn {
      background: #4caf50;
      color: #fff;
      width: 2.3rem;
      padding: 0.12rem 0;
      border: none;
      cursor: pointer;
      margin-top: 0.2rem;
      &:hover {
        background: #4caf40;
      }
      border-radius: 8px;
    }
    .loader {
      border: 0.08rem solid #f3f3f3;
      border-top: 0.08rem solid #4caf50;
      border-radius: 50%;
      width: 0.5rem;
      height: 0.5rem;
      animation: spin 2s linear infinite;
      margin: 0.2rem auto;
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
</style>
