<template>
  <!-- 顶部导航栏组件 -->
  <div>
    <div class="nav-box" :class="[{ isScrolled: isScrolled }]">
      <div ref="navBar" class="nav-bar">
        <div class="container row-between">
          <div class="nav-left">
            <a :href="$origin('/')" class="logo">
              <img
                :src="$getFile(content.logo)"
                class="logo-jpg"
                v-if="content.logo"
                alt=""
              />
              <!-- <img class="logo-jpg" :src="$getFile('navBar/logo.jpg')"  alt=""> -->
              <span v-else>{{ content.title }}</span>
            </a>
            <div v-for="(item, key) in content.converts" :key="key">
              <DropDown :content="item" />
            </div>
          </div>
          <div class="nav-right row">
            <el-menu
              mode="horizontal"
              background-color="var(--theme-primary-hover)"
              text-color="#fff"
              active-text-color="#fff"
              class="products"
              v-if="content.showproducts"
            >
              <el-submenu index="2">
                <template slot="title">Products</template>
                <el-menu-item
                  @click="handleSelect(item)"
                  v-for="(item, index) in list"
                  :key="index"
                  >{{ item.name }}</el-menu-item
                >
              </el-submenu>
            </el-menu>
            <a :href="supportLink" v-if="content.support">{{
              content.support
            }}</a>
            <a :href="$origin(content.fastLink)" v-if="content.fastClick">{{
              content.fastClick
            }}</a>
            <login-wrap ref="loginWrap" :content="content.loginInfo" />
          </div>
        </div>
        <top-dialog :show="showTools">
          <div class="tools-grid">
            <div v-for="(items, index) in converts1" :key="index">
              <div class="tool-title">{{ items.title }}</div>
              <a
                v-for="(item, idx) in items.list"
                :key="idx"
                :href="$origin(item.path)" 
                :to="{ path: item.path }"
              >
                <div class="tool-name">{{ item.title }}</div>
              </a>
            </div>
          </div>
        </top-dialog>
      </div>
    </div>
    <div class="placeholder"></div>
  </div>
</template>

<script>
import utils from "@/common/utils";
import DropDown from "./ui/DropDown.vue";
import TopDialog from "./ui/TopDialog.vue";

export default {
  name: "NavBar",
  components: { DropDown, TopDialog },
  props: {
    content: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      isScrolled: false,
      activeIndex: "1",
      color: "",
      // 手机端代码支持
      showMobileHeader: false,
      // end
      showTools: false,
      btnType: this.content.login,
      list: [
        { name: "AI Animation", path: "/products/ai-animation", type: 3 },
        { name: "Text To Speech", path: "/products/text-to-speech", type: 1 },
        { name: "Vincent Diagram", path: "/products/vincent-diagram", type: 2 },
      ],
    };
  },
  computed: {
    supportLink() {
      const changeDomain = (domain) => {
        const prot = window.location.protocol;
        if (domain.includes("www.")) {
          return `${prot}//${domain.replace("www.", "support.")}`;
        }
        // 如果域名不包含 www.，则在开头添加 support.
        var protocolSeparator = domain.indexOf("://");
        if (protocolSeparator !== -1) {
          var protocol = domain.substring(0, protocolSeparator + 3);
          var restOfUrl = domain.substring(protocolSeparator + 3);
          return protocol + "support." + restOfUrl;
        } else {
          return `${prot}//support.${domain}`;
        }
      };
      return changeDomain(window.location.host);
    },
    converts1() {
      return [];
      // const convertObj = Object.fromEntries(
      //   Object.keys(this.$t('navBar').converts).slice(3, this.$t('navBar').converts.length).map(key => [key, this.$t('navBar').converts[key]])
      // )
      // return convertObj
    },
  },
  beforeMount() {
    // 支持手机端代码
    this.headerCtrl();
    window.addEventListener("resize", this.headerCtrl.bind(this));
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    // this.setFontColor(this.topEleBg)
    // end
    window.addEventListener("click", this.handleToolClick.bind(this));
    this.$store.commit("SAVE_LOGIN_INFO", this.content.loginInfo);

    if (this.$route.name !== "Home") {
      this.isScrolled = true;
    }
  },

  methods: {
    handleSelect(item) {
      const funType = sessionStorage.getItem("funType");
      if (Number(funType) === item.type) {
        window.location.href = "/";
      } else {
        window.location.href = item.path;
      }
    },
    handleScroll() {
      if (this.$route.name !== "Home") {
        this.isScrolled = true;
        return;
      }
      this.isScrolled = window.scrollY > 0;
    },
    addPoint(name) {
      // this.$api.saveOperationLog({
      //
      //   event_name,
      //   event_key: event_name,
      // })
    },
    // handleLogin() {
    //   this.$store.commit('changeDialog', { name: 'FormLayout', active: 'Login' })
    // },
    toTools(items) {
      this.$router.push({ path: this.$origin(items.name) });
    },
    headerCtrl(e) {
      if (document.body.getBoundingClientRect().width <= 980) {
        this.showMobileHeader = true;
      } else {
        this.showMobileHeader = false;
      }
    },
    // val：navBar背景色，通过计算得出navbar文字深浅颜色
    setFontColor(val) {
      this.$nextTick(() => {
        let bg = utils.set16ToRgb(val);
        let r = 0;
        if (val.match(/#\w{6,8}/)) {
          r = parseInt(bg.substr(4, 3));
        }
        this.color = 255 - r > 100 ? "#FFFFFF" : "#011E15";
      });
    },
    toolsBoxClick() {
      this.showTools = !this.showTools;
    },
    handleToolClick(e) {
      if (!e.path) {
        // 不存在则遍历target节点
        let target = e.target;
        e.path = [];
        while (target.parentNode !== null) {
          e.path.push(target);
          target = target.parentNode;
        }
        // 最后补上document和window
        e.path.push(document, window);
      }
      let converIndex = [...e.path].indexOf(this.$refs["convert-box"]);
      if (!([...e.path].indexOf(this.$refs["tools-main"]) === -1)) {
        this.showTools = !this.showTools;
      } else if (converIndex >= 0 && converIndex < 5) {
        this.showTools = true;
      } else {
        this.showTools = false;
      }
    },
  },
};
</script>
<style lang="scss">
.el-menu.el-menu--horizontal {
  border-bottom: none !important;
}
.el-submenu__title,
.el-menu-item {
  border-bottom: none !important;
  background: rgba(var(--theme-primary-hover), 1) !important;
  &:hover {
    color: rgba(#fff, 0.8) !important;
    background: rgba(var(--theme-primary-hover), 0.4) !important;
  }
}
</style>
<style lang="scss" scoped>
$commonColor: "#419EFF"; //统一颜色

@mixin flex-around {
  display: flex;
  justify-content: space-around;
}

.shadow {
  box-shadow: 0 1px 5px 0 #b8b8b880;
  border-bottom: none;
}

@media #{'only screen and (min-width: 992px)'} {
  .placeholder {
    // height: 80px;
    width: 100%;
  }

  .nav-box {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 20;
    background-color: var(--theme-primary);
    &.isScrolled {
      background-color: var(--theme-primary-hover);
    }
    font-family: Arial, Arial;
    .logo-jpg {
      max-width: 120px;
    }
    .nav-bar {
      position: relative;
      max-width: 1080px;
      width: 100%;
      height: 80px;
      margin: 0 auto;
      // padding: 0px 100px;
      box-sizing: border-box;
      font-size: 16px;
      font-weight: 400;
      color: var(--theme-primary-black);
      line-height: 20px;
      box-sizing: border-box;
      z-index: 2;
      transition: all 0.3s ease;

      .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;

        .nav-left {
          width: auto;
          @include flex-around;

          .logo {
            color: var(--theme-primary-suffix);
            font-size: 20px;
            font-weight: bold;
            white-space: nowrap;
            cursor: pointer;

            img {
              width: 100%;
            }
          }

          .item {
            padding: 10px 20px;
            user-select: none;

            a {
              cursor: pointer;
              display: block;
              text-decoration: none;
              color: var(--theme-primary-black);

              &:hover {
                span {
                  opacity: 0.8;
                }
              }
            }
          }
        }

        .nav-right {
          position: relative;
          width: auto;
          height: 100%;
          @include flex-around;
          align-items: center;

          a {
            color: var(--theme-primary-suffix);
            margin-right: 20px;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .tools-grid {
        width: 90%;
        max-width: 1340px;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 30px;
        margin: 0 auto;
        padding: 30px 0 41px;

        .tool-title {
          color: #777;
          font-size: 14px;
          line-height: 20px;
          text-align: left;
          margin-left: 12px;
          margin-bottom: 14px;
        }

        .tool-name {
          font-size: 14px;
          line-height: 46px;
          color: #05021e;
          padding-left: 12px;

          &:hover {
            background: #eef6fa;
          }
        }
      }
    }
  }
}

@media #{'only screen and (max-width: 991px)'} {
  .placeholder {
    // height: 1rem;
    width: 100%;
  }
  .products {
    display: none;
  }
  .nav-box {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 20;
    font-family: Arial, Arial;
    background-color: var(--theme-primary);
    &.isScrolled {
      background-color: var(--theme-primary-hover);
    }
    .nav-bar {
      position: relative;
      width: 100%;
      height: 1rem;
      padding: 0px 0.1rem;
      font-size: 0.3rem;
      font-weight: 400;
      line-height: 20px;
      box-sizing: border-box;
      transition: all 0.3s ease;
      z-index: 2;
      color: var(--theme-primary-black);
      .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;

        .nav-left {
          width: auto;
          @include flex-around;

          .logo {
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: bold;
            text-transform: uppercase;
            white-space: nowrap;
            color: var(--theme-primary-suffix);
            cursor: pointer;

            img {
              width: 1rem;
            }
          }

          .item {
            padding: 10px 20px;
            user-select: none;

            a {
              display: block;
              text-decoration: none;
              color: var(--theme-primary-black);
              cursor: pointer;

              &:hover {
                span {
                  opacity: 0.8;
                }
              }
            }
          }
        }

        .nav-right {
          position: relative;
          width: auto;
          height: 100%;
          @include flex-around;
          align-items: center;

          a {
            margin-right: 10px;
            color: var(--theme-primary-suffix);

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
</style>