module.exports = {
 conversion: {
  trySample: "Try a Sample",
  tryTips: "One click opens the door to global language interoperability, a language bridge across borders, allowing you to easily break through the language barrier and navigate the information ocean!",
  title: "Target Language",
  original: "Original language",
  copy: 'Copy',
  downAudio: 'Download Audio',
  copySuccess: 'Copy Success',
  translate: 'Translate',
  downSuccess: 'The audio has been downloaded for you',
  errorLang: 'Please select your language',
  "translationSelect": "Please select the language of translation",
  translateError: "Functions are being improved",
  "placeholder": "Please select a language. Multiple selections are allowed.",

 },
 jsonEdit: {
  config: 'Config',
  conversion: "Conversion",
  form: {
   errorTips: "The json content is incorrect",
   add: 'Add',
   ignore: 'Ignore',
   keyIgnore: 'Key Ignore',
   translate: "Translate",
   ignoreplaceholder: "Please enter the ignored content such as: .png",
   ignorekeyplaceholder: "Please enter the ignored content such as: .png",
  }
 }
}