<template>
  <div class="account">
    <div class="profile">
      <div class="user-info">
        <div class="user-info-left">
          <img class="user-icon" :src="$getFile('user-icon.jpg')" alt="" />
          <div>
            <div class="email-title">{{ content.enailTitle }}</div>
            <div class="email">{{ email }}</div>
          </div>
        </div>
        <div class="user-info-right">
          <div>{{ `Valid Until: ${formattedTime}` }}</div>
          <!-- <div>{{ expirationTime ? 'Subscription Plan' : 'Normal plan' }}</div> -->
          <div class="submit—mini" @click="handleUnsubscribe">
            {{
              subscriptionStatus ===1 ? content.unsubscribe : "Not subscribed"
            }}
          </div>
        </div>
      </div>
      <el-form
        ref="form"
        label-position="top"
        :inline="true"
        :model="form"
        label-width="80px"
      >
        <el-form-item label="email">
          <el-input v-model="form.email" :disabled="true" />
        </el-form-item>
        <el-form-item :label="content.labalList.firstName">
          <el-input v-model="form.firstName" />
        </el-form-item>
        <el-form-item :label="content.labalList.lastName">
          <el-input v-model="form.lastName" />
        </el-form-item>
        <el-form-item :label="content.labalList.address">
          <el-input v-model="form.address" />
        </el-form-item>
        <el-form-item v-if="content.emailSubscription">
          <div class="emailSubscription-warp">
            <div>{{ content.emailSubscription.title }}</div>
            <el-switch
              v-model="form.emailSubscription"
              active-color="#13ce66"
              inactive-color="#ff4949"
            />
          </div>
          <div class="emailSubscription-des">
            {{ content.emailSubscription.des }}
          </div>
          <!-- <el-checkbox v-model="form.emailSubscription">You will allow us to push on-site mail for you.</el-checkbox> -->
        </el-form-item>
        <!-- <el-form-item :label="content.labalList.country">
          <el-select
            v-model="form.country"
            :placeholder="content.inputTip"
            style="width: 360px"
          >
            <el-option
              v-for="(item, index) in contryList"
              :key="index"
              :label="item.name"
              :value="item.iso"
            />
          </el-select>
        </el-form-item> -->
      </el-form>
      <el-button
        class="submit"
        type="primary"
        :loading="loadStart"
        @click="onSubmit"
      >
        {{ loadStart ? "" : content.submitBtn }}
      </el-button>
    </div>
    <div class="tool-warp">
      <SpeakToText v-if="siteType===1" :content="{}" />
      <AIText v-if="siteType===3" />
      <conversion v-if="siteType===4" />
      <AIText_w v-if="siteType===5" />
    </div>
  </div>
</template>

<script>
import { contryList } from "@/common/countries.js";
import Loading from "./ui/Loading.vue";
// import AIText from "@/components/AIText.vue"
export default {
  name: "TermsOfUse",
  props: {
    content: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  components: { Loading },
  data() {
    let currenPass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.content.errTip1));
      }
      callback();
    };
    let newPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.content.errTip1));
      } else if (value.length < 6 || value.length > 32) {
        callback(new Error(this.content.errTip2));
      } else {
        callback();
      }
    };
    let checkPassport = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.content.errTip3));
      } else if (value !== this.passForm.newPass) {
        callback(new Error(this.content.errTip4));
      } else {
        callback();
      }
    };
    return {
      siteType:1,
      email: "",
      typeShow: "yyzwz",
      loadStart: false,
      expirationTime: false,
      subscriptionStatus: 1,
      formattedTime: "- -",
      transactionCode:'',
      form: {
        firstName: "",
        lastName: "",
        address: "",
        country: "",
      },
      passForm: {
        currenPass: "",
        newPass: "",
        checkPass: "",
      },
      contryList,
      rules: {
        currenPass: [
          { required: true, validator: currenPass, trigger: "blur" },
        ],
        newPass: [{ required: true, validator: newPass, trigger: "change" }],
        checkPass: [
          { required: true, validator: checkPassport, trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.getInfo();
    this.typeShow = sessionStorage.getItem("funType");
  },
  methods: {
    getSubscribeState(inputTime, currentTime, status) {
      const SubscribeState = {
        /** 表示已取消订阅 */
        Cancel: "-1",
        /** 表示不是会员 */
        NotMember: "0",
        /** 表示会员期间 */
        Member: "1",
        /** 表示会员过期 */
        MemberExpire: "2",
      };
      // status //0未购买 1激活 2取消
      const input = new Date(inputTime).getTime();
      const current = new Date(currentTime).getTime();
      if (status === 1 && input > current) {
        return SubscribeState.Member;
      }
      if (status === 2) {
        return SubscribeState.MemberExpire;
      }
      if (input < current) {
        return SubscribeState.Cancel;
      } 
      return SubscribeState.MemberExpire;
    },
    async getInfo() {
      const user_token_value = localStorage.getItem("user_token_value");
      if (!user_token_value) {
        localStorage.removeItem("user_token_key");
        localStorage.removeItem("user_token_value");
        localStorage.removeItem("user_email");
        localStorage.removeItem("userInfo");
        // this.$router.push({ path: "/" });
        window.location.href = this.$origin('/')
        return;
      }
      let host = this.$extractDomain(window.location.hostname);
      let lang = this.$route.params.lang || "en";
      if (lang === "id") {
        host = `${host}/${lang}`;
      }
      let siteJson = await import(`../site-json/${host}/site.json`);
      this.siteType = Number(siteJson.type)
      this.$api.GetUserInfo().then((result) => {
        if (result.code === 200) {
          localStorage.setItem("userInfo", JSON.stringify(result.data));
          this.form = result.data;
          this.email = result.data.email;
          if (result.data.membershipList.length > 0) {
            const status1 = result.data.membershipList[0].expirationTime>new Date().getTime()?'1':'0';
          this.transactionCode = result.data.membershipList[0].transactionCode;
            const status = this.getSubscribeState(
              new Date(result.data.membershipList[0].expirationTime),
              new Date(),
              result.data.membershipList[0]?.subscription?.subscriptionStatus ||status1
            );
            this.subscriptionStatus = Number(status);
          } else {
            this.subscriptionStatus = 0;
          }
          if (result.data.membershipList.length === 0) {
            return (this.expirationTime = false);
          }
          const now = new Date();
          const date = new Date(result.data.membershipList[0].expirationTime);
          const year = date.getFullYear(); // 获取年份
          const month = String(date.getMonth() + 1).padStart(2, "0"); // 获取月份，并补零
          const day = String(date.getDate()).padStart(2, "0"); // 获取日期，并补零
          this.formattedTime = `${year}-${month}-${day}`;
          if (date > now) {
            this.expirationTime = true;
          } else {
            this.expirationTime = false;
          }
        }
      });
    },
    onSubmit() {
      this.loadStart = true;
      this.$api
        .SetUserInfo(this.form)
        .then((result) => {
          if (result.code === 200) {
            this.$message({
              message: this.content.suceeTip1,
              type: "success",
            });
            this.getInfo();
          }
        })
        .catch((err) => {
          if (err.msg) {
            this.$message({
              message: err.msg,
              type: "error",
            });
          }
        })
        .finally(() => {
          this.loadStart = false;
        });
    },
    // 取消订阅
    handleUnsubscribe() {
      if (this.subscriptionStatus === 1) {
        this.$confirm("Do you confirm the unsubscription?", "tips", {
          confirmButtonText: "sure",
          cancelButtonText: "cancel",
          type: "warning",
        })
          .then(() => {
            this.$api
              .Unsubscribe({ reason: "User manually clicks", transactionCode:this.transactionCode })
              .then((result) => {
                // if (result.success == 1) {
                this.$message({
                  message: "Unsubscribed successfully",
                  type: "success",
                });
                this.getInfo();
                // }
              })
              .catch((err) => {
                this.$message({
                  message: "Unsubscribed error",
                  type: "error",
                });
                // console.log(err, '>>>> err')
              });
          })
          .catch(() => {
            console.log("cancel");
          });
      }else{
        this.$message({
          message: "You have not subscribed",
          type: "warning",
        });
      }
    },
  },
};
</script>
<style lang="scss">
.el-checkbox {
  &.is-checked {
    .el-checkbox__inner {
      background-color: #329678 !important;
      border-color: #329678 !important;
    }
    .el-checkbox__label {
      color: #329678 !important;
    }
  }
}
</style>
<style scoped lang="scss">
.emailSubscription-warp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  max-width: 400px;
  width: 100%;
}
.emailSubscription-des {
  max-width: 400px;
  width: 100%;
  line-height: normal;
  font-size: 12px;
  color: #999;
}
.tool-warp {
  width: 100%;
}

@media (min-width: 981px) {
  .account {
    width: 100%;
    background: #f2f3f5;
    display: flex;
    flex-direction: column;
    align-items: center;

    .profile {
      width: 1200px;
      min-height: 461px;
      border-radius: 8px;
      background: #fff;
      margin-top: 40px;
      padding: 24px 48px;
      box-sizing: border-box;
      margin-bottom: 100px;
      margin-top: 100px;

      .title {
        color: #202124;
        font-family: Poppins-Medium;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .user-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f5f5f5;
        margin-bottom: 40px;

        .user-info-left {
          display: inline-flex;
          gap: 16px;
          align-items: center;
          margin-top: 24px;
          margin-bottom: 24px;

          .user-icon {
            width: 42px;
          }
        }
      }

      .user-info-right {
        display: flex;
        align-items: end;
        flex-direction: column;
        color: #202124;
        font-family: Poppins-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;

        div {
          margin-bottom: 24px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .email-title {
        color: #202124;
        font-size: 14px;
        font-weight: 500;
        text-transform: capitalize;
        font-family: Poppins-Medium;
        line-height: normal;
      }

      .email {
        color: #202124;
        font-size: 18px;
        font-weight: 400;
        margin-top: 5px;
      }

      ::v-deep .el-input {
        width: 360px;
      }

      ::v-deep .el-form-item__label {
        padding: 0;
      }

      ::v-deep .el-form-item {
        margin-right: 190px;
      }

      .submit {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        font-family: Poppins-Medium;
        position: relative;
        width: 108px;
        height: 42px;
        border-radius: 4px;
        border: none;
        background: var(--theme-primary-color);
        line-height: 42px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
      }

      .submit—mini {
        font-family: Poppins-Medium;
        height: 24px;
        padding: 0 10px;
        border-radius: 4px;
        background: var(--theme-primary-color);
        line-height: 24px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
      }

      .submit-mini-los {
        font-family: Poppins-Medium;
        height: 24px;
        padding: 0 10px;
        border-radius: 4px;
        background: #565a59;
        line-height: 24px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        cursor: no-drop;
      }
    }

    .last-wrap {
      width: 1200px;
      border-radius: 8px;
      background: #fff;
      margin-top: -40px;
      padding: 24px 48px 30px;
      box-sizing: border-box;
      margin-bottom: 100px;

      .title {
        color: #202124;
        font-family: Poppins-Medium;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 50px;
      }
    }

    .password {
      width: 1200px;
      height: 444px;
      border-radius: 8px;
      background: #fff;
      margin-top: 40px;
      margin-bottom: 120px;
      padding: 24px 48px;
      box-sizing: border-box;

      .title {
        color: #202124;
        font-family: Poppins-Medium;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .explain {
        width: 927px;
        color: #adaeaf;
        font-family: Poppins-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 4px;
        margin-bottom: 40px;
      }

      ::v-deep .el-input {
        width: 360px;
      }

      ::v-deep .el-form-item__label {
        padding: 0;
      }

      ::v-deep .el-form-item {
        margin-right: 190px;
      }

      .change-pass {
        margin-top: 10px;
        padding: 10px 28px;
        border-radius: 4px;
        background: var(--theme-primary-color);
        color: #fff;
        font-size: 14px;
        line-height: normal;
        text-transform: capitalize;
        display: inline-block;
        cursor: pointer;
        position: relative;
      }

      .curren-pass {
        ::v-deep .el-input__inner {
          padding-right: 100px;
        }

        ::v-deep .el-input__clear {
          margin-right: 70px;
        }
      }

      .curren-pass-input {
        position: relative;
      }

      .forgot {
        color: #416beb;
        font-family: Poppins-Medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        position: absolute;
        right: 16px;
        top: 10px;
        cursor: pointer;

        &:hover {
          color: #329678;
        }
      }
    }

    ::v-deep .el-form-item__label {
      color: #797b80;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 4px;
    }

    ::v-deep .el-input__inner {
      color: #202124;
      font-family: Poppins-Regular;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

@media (max-width: 980px) {
  .account {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .profile {
      border-radius: 0.08rem;
      background: #fff;
      margin-top: 0.34rem;
      box-sizing: border-box;
      margin-bottom: 0.8rem;
      padding: 0 0.25rem;

      .title {
        color: #202124;
        font-family: Poppins-Medium;
        font-size: 0.42rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .user-info {
        margin-bottom: 0.5rem;

        .user-info-left {
          display: inline-flex;
          gap: 0.35rem;
          align-items: center;
          margin-top: 0.4rem;
          margin-bottom: 0.4rem;

          .user-icon {
            width: 42px;
          }

          .email {
            color: #adaeaf;
            font-size: 0.28rem;
            font-style: normal;
            font-family: Poppins-Medium;
            font-weight: 400;
            line-height: normal;
            margin-top: 0.08rem;
          }

          .email-title {
            color: #202124;
            font-family: Poppins-Medium;
            font-size: 0.28rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
          }
        }

        .user-info-right {
          color: #202124;
          font-family: Poppins-Regular;
          font-size: 0.28rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
          text-align: left;

          div {
            margin-bottom: 0.24rem;

            &:last-child {
              margin-bottom: 0;
            }
          }

          .submit—mini {
            margin-top: 10px;
            font-family: Poppins-Medium;
            position: relative;
            width: 96px;
            height: 24px;
            border-radius: 4px;
            background: var(--theme-primary-color);
            line-height: 24px;
            text-align: center;
            color: #fff;
            font-size: 14px;
            cursor: pointer;
          }

          .submit-mini-los {
            font-family: Poppins-Medium;
            width: 2rem;
            height: 0.5rem;
            padding: 0 0.1rem;
            border-radius: 0.1rem;
            background: #565a59;
            line-height: 0.5rem;
            text-align: center;
            color: #fff;
            font-size: 14px;
            cursor: no-drop;
          }
        }
      }

      ::v-deep .el-input {
        width: 6.9rem;
      }

      ::v-deep .el-form-item__label {
        padding: 0;
      }

      ::v-deep .el-form-item {
        width: 6rem;
        margin-right: 0;
      }

      .submit {
        width: 100%;
        height: 0.84rem;
        font-family: Poppins-Medium;
        color: #fff;
        font-size: 0.28rem;
        font-style: normal;
        font-weight: 600;
        text-transform: capitalize;
        position: relative;
        border: none;
        border-radius: 0.08rem;
        background: var(--theme-primary-color);
        text-align: center;
        cursor: pointer;
      }
    }

    .last-wrap {
      width: 100%;
      border-radius: 0.08rem;
      background: #fff;
      padding: 0.3rem;
      box-sizing: border-box;
      margin-bottom: 1rem;

      .title {
        color: #202124;
        font-family: Poppins-Medium;
        font-size: 0.42rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 0.3rem;
      }
    }

    .password {
      width: 6.86rem;
      border-radius: 0.08rem;
      background: #fff;
      margin-top: 0.34rem;
      padding: 0.3rem;
      box-sizing: border-box;
      margin-bottom: 0.63rem;

      .title {
        color: #202124;
        font-family: Poppins-Medium;
        font-size: 0.42rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .explain {
        color: #adaeaf;
        font-family: Poppins-Regular;
        font-size: 0.28rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 0.1rem;
        margin-bottom: 0.5rem;
      }

      ::v-deep .el-input {
        width: 6.26rem;
      }

      ::v-deep .el-form-item__label {
        padding: 0;
      }

      ::v-deep .el-form-item {
        margin-right: 0;
      }

      .change-pass {
        width: 6.26rem;
        height: 0.84rem;
        margin-bottom: 0.2rem;
        font-family: Poppins-Medium;
        color: #fff;
        font-size: 0.28rem;
        font-style: normal;
        font-weight: 600;
        line-height: 0.84rem;
        text-transform: capitalize;
        border-radius: 0.08rem;
        background: var(--theme-primary-color);
        text-align: center;
        position: relative;
      }

      .curren-pass {
        ::v-deep .el-input__inner {
          padding-right: 1.9rem;
        }

        ::v-deep .el-input__clear {
          margin-right: 1.3rem;
        }
      }

      .curren-pass-input {
        position: relative;
      }

      .forgot {
        color: #416beb;
        font-family: Poppins-Medium;
        font-size: 0.28rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        position: absolute;
        right: 0.2rem;
        top: 0.16rem;
        cursor: pointer;
      }
    }
  }

  ::v-deep .el-form-item__label {
    color: #797b80;
    font-size: 0.28rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0.08rem;
  }

  ::v-deep .el-input__inner {
    color: #202124;
    font-family: Poppins-Regular;
    font-size: 0.32rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

::v-deep .el-button--primary {
  background-color: #416beb;
  border-color: #416beb;

  &:hover {
    background-color: #329678;
    border-color: #329678;
  }
}
</style>