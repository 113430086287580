<template>
  <div class="koutu_warp">
    <div class="koutu_step">
      <p class="title">{{ content.title }}</p>
      <div class="area">
        <div v-for="(item, index) in content.list" :key="index" class="item">
          <img :src="$getFile(item.img)" v-if="item.img" alt="" />
          <p class="subtitle">{{ item.subtitle }}</p>
          <p class="explain">{{ item.text }}</p>
        </div>
      </div>
    </div>
    <img :src="$getFile(content.bg)" class="koutu_bg" v-if="content.bg" alt="" />
  </div>
</template>
  
  <script>
export default {
  name: "Koutu_Step",
  props: {
    content: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
};
</script>
  
  <style scoped lang="scss">
.koutu_warp {
  position: relative;
  .koutu_bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}
@media #{'only screen and (min-width: 992px)'} {
  .koutu_step {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 1080px;
    margin: 0 auto;
    padding: 100px 0;
    font-family: Arial, Arial;
    position: relative;
    z-index: 1;
    .title {
      margin-bottom: 12px;
      font-size: 60px;
      font-weight: 900;
      color: var(--theme-primary-black);
      line-height: 1.3;
      text-align: center;
      width: 70%;
      margin: 0 auto 80px;
    }

    .area {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 40px;
      width: 100%;
      margin-top: 42px;

      .item {
        position: relative;
        // padding: 100px 20px 60px;
        background: #f7f3ff;
        box-shadow: 0px 2px 8px 0px rgba(92, 104, 130, 0.1);
        border-radius: 36px;
        overflow: hidden;
        transition: all 0.3s;
        text-align: center;
        overflow: visible;
        padding: 60px 20px;
        &:hover {
          transform: translateY(-5px);
          -webkit-transform: translateY(-5px);
          -moz-transform: translateY(-5px);
          -ms-transform: translateY(-5px);
          -o-transform: translateY(-5px);
          background: var(--theme-primary-color);
          box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.15);
          .subtitle {
            // color: var(--theme-primary-color);
          }
        }
        img {
          width: 100%;
          // position: absolute;
          // top: -60px;
          // left: 50%;
          // transform: translateX(-50%);
        }
        .subtitle {
          margin-bottom: 10px;
          font-size: 25px;
          font-weight: bold;
          color: var(--theme-primary-black);
          line-height: 1.2;
          text-transform: uppercase;
        }
        .explain {
          margin-top: 20px;
          font-size: 20px;
          color: var(--theme-primary-black);
          line-height: 1.2;
        }
      }
    }
  }
}

@media #{'only screen and (max-width: 991px)'} {
  .koutu_step {
    width: 100%;
    padding: 0.8rem 0;
    font-family: Arial, Arial;
    position: relative;
    z-index: 1;
    .title {
      margin: 0 auto 1.5rem;
      font-size: 0.48rem;
      color: var(--theme-primary-black);
      text-align: center;
      font-weight: 900;
    }
    .area {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 1rem;
      margin-top: 1rem;

      .item {
        position: relative;
        padding: 1rem 0.4rem 1rem;
        background: #f7f3ff;
        border-radius: 0.2rem;
        //   transition: all .3s;
        text-align: center;
        overflow: visible;
        margin: 0 0.3rem;

        img {
          position: absolute;
          width: 2rem;
          height: 2rem;
          top: -15%;
          left: 50%;
          transform: translateX(-50%);
        }
        .subtitle {
          margin-bottom: 0.3rem;
          font-size: 0.5rem;
          font-weight: bold;
          color: var(--theme-primary-black);
          // line-height: 1.2;
          text-transform: uppercase;
        }
        .explain {
          // margin-top: 20px;
          font-size: 0.3rem;
          color: var(--theme-primary-black);
          // line-height: 1.2;
        }
      }
    }
  }
}
</style>