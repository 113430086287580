<template>
  <div class="step-wrapper" id="STEP">
    <div class="wrapper">
      <h2>{{ content.title }}</h2>
      <ul>
        <li v-for="(item,index) in list" :key="index">
          <div class="identification">
            <div class="line_wrap">
              <img class="dian" src="@/assets/images/step.svg" />
              <p class="linee"></p>
            </div>
            <div class="title">{{
               item.title 
            }}</div>
          </div>
          <p>{{ item.des }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "step",
  props: {
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
    titleTag: {
      type: String,
      default: "p",
    },
  },
  data() {
    return {
      list:[]
    };
  },
  mounted(){
    this.list = [
      {
      title:this.content.problem,
      des:this.content.answer
    },
      {
      title:this.content.problem2,
      des:this.content.answer2
    },
      {
      title:this.content.problem3,
      des:this.content.answer3
    },
      {
      title:this.content.problem4,
      des:this.content.answer4
    },
  ]
  },
  methods: {
    // 滚动到定位手机位置
    scrollLocate() {
      document.querySelector(`#LOCATE`).scrollIntoView({
        block: "end",
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 981px) {
  .step-wrapper {
    display: flex;

    .wrapper {
      width: 1240px;
      margin: 90px auto;
      text-align: center;

      h2 {
        color: #021222;
        text-align: center;
        font-family: Manrope;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 64px;
      }

      ul {
        display: flex;
        text-align: left;
        margin-bottom: 64px;

        li {
          flex: 1;
          margin-right: 64px;

          &:last-child {
            margin-right: 0;
          }

          .identification {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;

            .step_img {
              width: 197px;
              margin-bottom: 14px;
            }

            .title {
              width: 100%;
              font-size: 24px;
              font-family: Manrope-Bold, Manrope;
              font-weight: bold;
              color: #021222;
              line-height: 33px;
            }

            .line_wrap {
              display: flex;
              align-items: center;
              gap: 13px;
              margin-bottom: 24px;

              .dian {
                width: 20px;
              }

              .linee {
                width: 207px;
                height: 1px;
                background: #24adfe;
              }
            }
          }

          > p:last-child {
            font-size: 16px;
            font-family: Manrope-Regular, Manrope;
            font-weight: 400;
            color: #021222;
            line-height: 22px;
            margin-top: 8px;
          }
        }
      }

      .step_btn {
        min-width: 200px;
        padding: 18px 46px;
        box-sizing: border-box;
        border-radius: 4px;
        background: #24adfe;
        color: #fff;
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        white-space: nowrap;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 980px) {
  .step-wrapper {
    padding: 1rem 0.32rem;
    padding-bottom: 1rem;

    .wrapper {
      text-align: center;

      h2 {
        font-size: 0.48rem;
        font-family: Manrope-Bold, Manrope;
        font-weight: bold;
        color: #021222;
        line-height: 0.65rem;
        margin-bottom: 0.8rem;
      }

      ul {
        text-align: left;

        li {
          flex: 1;
          margin-bottom: 0.64rem;

          &:last-child {
            margin-bottom: 0;
          }

          .identification {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .step_img {
              width: 3.68rem;
              height: 2.1rem;
              margin: 0 auto;
              margin-bottom: 0.24rem;
            }

            .title {
              width: 100%;
              font-size: 0.4rem;
              font-family: Manrope-Bold, Manrope;
              font-weight: bold;
              color: #021222;
              line-height: 0.44rem;
            }

            .line_wrap {
              display: flex;
              align-items: center;
              gap: 13px;
              margin-bottom: 0.32rem;

              .dian {
                width: 0.4rem;
              }

              .linee {
                width: 6.3rem;
                height: 1px;
                background: #24adfe;
              }
            }
          }

          > p:last-child {
            font-size: 0.32rem;
            font-family: Manrope-Regular, Manrope;
            font-weight: 400;
            color: #021222;
            line-height: 0.42rem;
            margin-top: 0.24rem;
          }
        }
      }

      .step_btn {
        display: none;
      }
    }
  }
}
</style>