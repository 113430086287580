<template>
  <div :class="[
      'public-btn',
      type,
      customClass,
      { 'primary-load': loading },
      { [`${type}-active`]: disabled },
      { 'is-plain': plain },
      { [`${type}-${disabledClass}`]: !disabled && disabledClass }
    ]" ontouchstart="" @click.stop="handleClick">
    <i v-show="loading" class="load" />
    <span>{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: 'AButton',

  props: {
    // 类型
    type: {
      type: String,
      default: 'primary'
    },
    // 按钮文案
    text: {
      type: String,
      default: ''
    },
    // 是否可以点击
    disabled: {
      type: Boolean,
      default: false
    },
    // 加载
    loading: {
      type: Boolean,
      default: false
    },
    // 自定义类
    customClass: {
      type: String,
      default: ''
    },
    // 无装饰
    plain: Boolean,
    // 是否可以点击
    disabledClass: {
      type: String,
      default: ''
    }
  },

  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: 980px) {
  .public-btn {
    height: 54px;
    line-height: 54px;
    border-radius: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    user-select: none;
    -ms-user-select: none; // IE
    -moz-user-select: none; // 火狐
    -khtml-user-select: none; // 早期浏览器
    -webkit-user-select: none;

    span {
      font-size: 20px;
      font-family: Manrope-Bold, Manrope;
      color: #ffffff;
      white-space: nowrap;
    }
  }

  .primary {
    background: var(--theme-btn-color);
  }

  .primary-active {
    background: var(--theme-btn-color);
    cursor: pointer;
    position: relative;
    pointer-events: auto !important;

    &::before {
      display: inline-block;
      content: "";
      width: 202px;
      height: 70px;
      position: absolute;
      top: 17px;
      left: 11px;
      z-index: -1;
    }
  }

  .primary-load {
    background: var(--theme-btn-color);
    pointer-events: none !important;
    user-select: none;
    -ms-user-select: none; // IE
    -moz-user-select: none; // 火狐
    -khtml-user-select: none; // 早期浏览器
    -webkit-user-select: none;
    opacity: .8;

    .load {
      width: 29px;
      height: 29px;
      margin-right: 6px;
      background: url("@/assets/images/loading-icon.webp") no-repeat;
      background-size: 100% 100%;
      animation: rotating 0.6s linear infinite;
    }
  }

  .warning {
    cursor: pointer;
    background: #ff8b2e;
  }

  .warning-active {
    pointer-events: auto !important;
  }

  .warning-active:hover {
    cursor: pointer;
    background: #e07a28;
    position: relative;
  }

  .danger-active {
    background: #eb4a49 !important;
    cursor: pointer;
    position: relative;
    pointer-events: auto !important;
  }

  .danger-active:hover {
    background: #ce4140 !important;
  }

  .danger-active:active {
    background: rgba(206, 65, 64, 0.9) !important;
  }

  .is-plain {
    background: #fff !important;
    height: 40px;
    line-height: 40px;
    border: 1px solid #3879f1;
    box-sizing: border-box;

    span {
      font-size: 16px;
      color: #3879f1 !important;
    }
  }

  .is-plain.primary-disabled {
    border: 1px solid rgba(56, 121, 241, 0.5) !important;

    span {
      color: rgba(56, 121, 241, 0.5) !important;
    }
  }

  .is-plain:hover {
    span {
      color: #fff !important;
    }
  }

  .danger.is-plain {
    background: #fff !important;
    height: 40px;
    line-height: 40px;
    border: 1px solid #d40000;
    box-sizing: border-box;
    pointer-events: auto !important;

    span {
      font-size: 16px;
      color: #d40000 !important;
    }
  }

  .danger-active.is-plain:hover {
    background: #d40000 !important;
    cursor: pointer;
    position: relative;
    pointer-events: auto !important;

    span {
      color: #fff !important;
    }
  }

  .is-plain.danger-disabled {
    background: #fff !important;
    pointer-events: none !important;
    user-select: none !important;
    -ms-user-select: none !important; // IE
    -moz-user-select: none !important; // 火狐
    -khtml-user-select: none !important; // 早期浏览器
    -webkit-user-select: none !important;
    border: 1px solid rgba(212, 0, 0, 0.5) !important;

    span {
      color: rgba(212, 0, 0, 0.5) !important;
    }
  }
}

@media (max-width: 981px) {
  .public-btn {
    height: .8rem;
    line-height: .8rem;
    border-radius: 0.54rem;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 0.3rem;
      font-family: Manrope-Bold, Manrope;
      color: #ffffff;
      white-space: nowrap;
    }
  }

  .primary {
    background: linear-gradient(270deg, #515aff 0%, #c960ff 100%);
  }

  .primary-active {
    background: linear-gradient(270deg, #515aff 0%, #c960ff 100%) !important;
    cursor: pointer;
    position: relative;
    pointer-events: auto;

    // &::before {
    //   display: inline-block;
    //   content: '';
    //   width: 6.32rem;
    //   height: 1.3rem;
    //   position: absolute;
    //   top: 0.31rem;
    //   left: 0.27rem;
    //   z-index: -1;
    //   background: url('#{$imgUrl}/pay/new/g@2x.webp#{$format}') no-repeat;
    //   background-size: 100% 100%;
    // }
  }

  .primary-active:active {
    background: linear-gradient(270deg, #515aff 0%, #c960ff 100%) !important;
  }

  .primary-load {
    // background: rgba(56, 121, 241, 0.5) !important;
    pointer-events: none !important;

    .load {
      display: inline-block;
      width: 0.4rem;
      height: 0.4rem;
      margin: 0 0.12rem 0 0;
      background: url("@/assets/images/loading-icon.webp") no-repeat;
      background-size: 100% 100%;
      animation: rotating 0.6s linear infinite;
    }
  }

  .warning {
    cursor: pointer;
    background: #ff8b2e;
  }

  .warning-active {
    pointer-events: auto !important;
  }

  .warning-active:hover {
    cursor: pointer;
    background: #e07a28;
    position: relative;
  }

  .danger-active {
    background: #eb4a49 !important;
    cursor: pointer;
    position: relative;
    pointer-events: auto;
  }

  .danger-active:active {
    background: rgba(206, 65, 64, 0.9) !important;
  }

  .is-plain {
    background: #fff !important;
    height: 0.8rem;
    line-height: 0.8rem;
    border-radius: 0.56rem;
    border: 0.02rem solid #3879f1;
    box-sizing: border-box;

    span {
      font-size: 16px;
      color: #3879f1 !important;
    }
  }

  .is-plain.primary-disabled {
    border: 0.02rem solid rgba(56, 121, 241, 0.5) !important;

    span {
      color: rgba(56, 121, 241, 0.5) !important;
    }
  }

  .is-plain:active {
    span {
      color: #fff !important;
    }
  }

  .danger.is-plain {
    background: #fff !important;
    height: 0.8rem;
    line-height: 0.8rem;
    border: 1px solid #d40000;
    box-sizing: border-box;
    border-radius: 0.56rem;
    pointer-events: auto !important;

    span {
      font-size: 16px;
      color: #d40000 !important;
    }
  }

  .danger-active.is-plain:active {
    background: #d40000 !important;
    cursor: pointer;
    position: relative;
    pointer-events: auto !important;

    span {
      color: #fff !important;
    }
  }

  .is-plain.danger-disabled {
    pointer-events: none !important;
    user-select: none !important;
    -ms-user-select: none !important; // IE
    -moz-user-select: none !important; // 火狐
    -khtml-user-select: none !important; // 早期浏览器
    -webkit-user-select: none !important;
    background: #fff !important;
    border: 1px solid rgba(212, 0, 0, 0.5) !important;

    span {
      color: rgba(212, 0, 0, 0.5) !important;
    }
  }
}
</style>
