import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
Vue.config.productionTip = false
import "swiper/css/swiper.min.css"
import VueSwiper from 'vue-awesome-swiper';
Vue.use(VueSwiper);
import i18n from "./lang/index";

// Vue.component('SwiperSlide', SwiperSlide);
// Swiper 代表最外侧容器
// SwiperSlide 代表每一张轮播图

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)

window.audioList = [];
import AButton from '@/components/ui/Button'
Vue.component('AButton', AButton)

import * as apis from '@/api';
Vue.prototype.$api = apis;

import { $extractDomain } from "@/utils/index"
Vue.prototype.$extractDomain = $extractDomain

Vue.prototype.$getFile = (name) => {
  return name && require('./assets/images/' + name)
}
//  处理url
Vue.prototype.$origin = function (path, isLink = false) {
  if (path.includes('https://') || path.includes('support@')) {
    return path
  }
  if (isLink) {
    return '/' + path
  }
  //  处理query，转化为链接格式
  const query = {}
  let target = this.$router.resolve({
    path, query
  })
  let lang = this.$route.params.lang || 'en';
  if (lang !== 'en') {
    return `${window.location.origin}/${lang}${target.href}`
  }
  return window.location.origin + target.href
}
//替换占位符
Vue.prototype.$replacePlaceholders = async (obj) => {
  let host = $extractDomain(window.location.hostname);
  // let lang = this.$route.params.lang || "en";
  // if (lang === "id") {
  //   host = `${lang}/${host}`;
  // }
  const siteInfo = await import(`./site-json/${host}/site.json`);
  function replacePlaceholders(obj, placeholders) {
    // 遍历对象的所有属性
    for (let key in obj) {
      if (typeof obj[key] === 'object') {
        // 递归处理嵌套对象
        replacePlaceholders(obj[key], placeholders);
      } else if (typeof obj[key] === 'string') {
        // 替换占位符
        obj[key] = obj[key].replace(/\{\{(\w+)\}\}/g, (match, placeholder) => {
          return placeholders[placeholder] || match;
        });
      }
    }
  }
  replacePlaceholders(obj, siteInfo)
  return obj
}
// 引入require.context函数
const requireComponent = require.context('./components', false, /\.vue$/);
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);
  const componentName = fileName.replace(/^\.\//, '').replace(/\.\w+$/, '');
  // console.log(componentName, ">>>")
  Vue.component(componentName, componentConfig.default || componentConfig);
});

new Vue({
  render: h => h(App),
  i18n,
  router,
  store
}).$mount('#app')
