var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"outWrap"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.priceSetup.length > 0),expression:"priceSetup.length > 0"}],staticClass:"price-container",attrs:{"id":"price"}},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.content.title))]),_c('p',{staticClass:"des"},[_vm._v(_vm._s(_vm.content.des))]),_c('div',{staticClass:"price-list"},_vm._l((_vm.priceSetup),function(item,idx){return _c('div',{key:idx,staticClass:"price-setup"},[_c('div',{staticClass:"tag"},[_vm._v(" "+_vm._s(item.title .replace(/{useTime1}/g, item.useTime1) .replace(/{useTime}/g, item.useTime))+" ")]),_c('div',{staticClass:"money",domProps:{"innerHTML":_vm._s(
            item.priceName
              .replace(/{money}/g, item.money)
              .replace(/{useTime}/g, item.useTime)
              .replace(/{useTime1}/g, item.useTime1)
          )}}),_c('ul',_vm._l((item.desList),function(line){return _c('li',{key:line,staticClass:"des"},[_vm._v(" "+_vm._s(line .replace(/{useTime}/g, item.useTime) .replace(/{useTime1}/g, item.useTime1))+" ")])}),0),_c('button',{staticClass:"pay-button",style:(`cursor: ${_vm.loading ? 'wait' : ''}`),on:{"click":function($event){return _vm.handlePay(item)}}},[_vm._v(" "+_vm._s(item.buttonTxt)+" ")]),(item.tip)?_c('p',{staticClass:"tip",domProps:{"innerHTML":_vm._s(
            item.tip
              .replace(/{money}/g, item.money)
              .replace(/{price}/g, item.price)
              .replace(/{useTime}/g, item.useTime)
              .replace(/{useTime1}/g, item.useTime1)
              .replace(/{useTime2}/g, item.useTime2)
          )}}):_vm._e()])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }