<template>
  <div class="utils-register-warp">
    <el-form
      :model="form"
      class="form"
      :rules="rules"
      ref="registerForm"
      label-position="top"
    >
      <el-form-item label="Email Address" prop="email">
        <el-input
          v-model="form.email"
          placeholder="Please enter your email"
        >
        </el-input>
      </el-form-item>
      <!-- <el-form-item label="Verification Code" prop="code">
        <el-input v-model="form.code" placeholder="Please enter the code">
          <template #suffix>
            <el-button
            class="btn"
              @click="sendVerificationCode"
              :disabled="countingDown"
              >{{ countingDown ? `${countdown}` : "Send Code" }}</el-button
            >
          </template></el-input
        >
      </el-form-item> -->
      <el-form-item label="Password" prop="password">
        <el-input
          type="password"
          v-model="form.password"
          placeholder="Please enter your password"
        ></el-input>
      </el-form-item>
      <el-form-item label="Confirm Password" prop="confirmPassword">
        <el-input
          type="password"
          v-model="form.confirmPassword"
          placeholder="Please confirm your password"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button :loading="loadStart" type="primary" class="btn register-btn" @click="register"
          >Register</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "utilsRegister",
  data() {
    return {
      confirmPassword: "",
      countdown: 60,
      countingDown: false,
      loadStart:false,
      form: {
        email: "",
        code: "",
        confirmPassword: "",
        password: "",
      },
      rules: {
        email: [
          {
            required: true,
            message: "Please enter your email address",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Please enter a valid email address",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Please enter your password",
            trigger: "blur",
          },
          {
            min: 6,
            message: "Password length should be at least 6 characters",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "Please enter a verification code",
            trigger: "blur",
          },
        ],
        confirmPassword: [
          {
            required: true,
            message: "Please confirm your password",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value !== this.form.password) {
                callback(new Error("The two passwords do not match"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    ...mapMutations(["OPEN_LOGIN"]),
    sendVerificationCode() {
      this.$refs.registerForm.validateField("email", (error) => {
        if (!error) {
          this.countingDown = true;
          const timer = setInterval(() => {
            if (this.countdown > 0) {
              this.countdown--;
            } else {
              this.countingDown = false;
              this.countdown = 60;
              clearInterval(timer);
            }
          }, 1000);
          this.$message.success("We have sent the CAPTCHA to your mailbox.");
        }
      });
    },
    register() {
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          this.loadStart =true
          this.$api
            .Register({
              identifier: this.form.email,
              credential: this.form.password,
            })
            .then((res) => {
              this.loadStart =false
              if (res.code === 200) {
                this.$message.success(
                  "Registration is successful, please log in"
                );
                this.OPEN_LOGIN(true);
              } else {
                this.$message.error(res.msg);
              }
              this.OPEN_LOGIN(true);
            });
        } else {
          this.$message.error("Please correct the errors in the form");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.utils-register-warp {
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
  gap: 0;
  padding-bottom: 100px;
  .form {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 0 32px;
    .el-input__suffix {
      right: 0;
    }
  }
  .btn {
    background: var(--theme-primary-login-btn);
    color: #fff;
    border: none;
  }
  .register-btn {
    width: 100%;
  }
}
@media (max-width: 980px) {
  .utils-register-warp {
    padding-top: 1rem;
    padding-bottom: 1rem;
    .el-form-item {
      margin-bottom: 0;
      .register-btn {
        margin-top: 1rem;
      }
    }
  }
}
</style>