<template>
  <div class="richtext-warp">
    <div v-html="content.richtext"></div>
  </div>
</template>

<script>
export default {
  name: "Banner",
  props: {
    content: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss">
@media (min-width: 981px) {
  .richtext-warp {
    width: 90%;
    max-width: 1200px;
    background-color: #fff;
    padding: 20px 30px;
    margin: 0 auto 0px;
    line-height: 28px;
    *{
      a{
        color: var(--theme-primary-color);
        text-decoration: underline;
      }
      p{
        font-weight: 300;
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 980px) {
  .richtext-warp {
    width: calc(90% - 1rem);
    background-color: #fff;
    margin: 0 auto 0px;
    line-height: 28px;
    padding: 0.4rem 0.6rem;
    font-size: 0.32rem;
    *{
      a{
        color: var(--theme-primary-color);
        text-decoration: underline;
      }
      p{
        font-weight: 300;
      }
    }
  }
}
</style>