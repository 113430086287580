<template>
    <div class="koutu_step">
      <p class="title">{{ content.title }}</p>
      <div class="area">
        <div v-for="(item, index) in content.list" :key="index" class="item">
          <p class="subtitle">{{ item.subtitle }}</p>
          <p class="explain">{{ item.text }}</p>
          <div class="stepText">{{ item.stepText }}</div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Koutu_Step_EZCut',
    props: {
      content: {
        type: Object,
        required: true,
        default: () => { }
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
 @media #{'only screen and (min-width: 992px)'} {
    .koutu_step {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 1080px;
      margin: 0 auto;
      padding:100px 0;
      font-family: Arial, Arial;
      .title {
        margin-bottom: 12px;
        font-size: 60px;
        font-weight: 900;
        color: var(--theme-primary-black);
        line-height: 1.3;
        text-align: center;
        width: 70%;
        margin: 0 auto 40px;
      }
  
      .area {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 40px;
        width: 100%;
        margin-top: 42px;
  
        .item {
          position: relative;
          padding: 100px 20px 60px;
          background: #F6F6F6;
          box-shadow: 0px 2px 8px 0px rgba(92, 104, 130, 0.1);
          border-radius: 10px;
          overflow: hidden;
          transition: all .3s;
          overflow: visible;
  
          &:hover {
            transform: translateY(-5px);
            -webkit-transform: translateY(-5px);
            -moz-transform: translateY(-5px);
            -ms-transform: translateY(-5px);
            -o-transform: translateY(-5px);
            background: white;
            box-shadow: 0px 20px 40px 0px rgba(0,0,0,0.15);
            .subtitle, .explain {
                color: white;
            }
            .stepText {
                background: white;
                color: var(--theme-primary-suffix);
            }
            background: var(--theme-primary-suffix);
          }
          .subtitle {
            margin-bottom: 10px;
            font-size: 25px;
            font-weight: bold;
            color: var(--theme-primary-suffix);
            line-height: 1.2;
          }
          .explain {
            margin-top: 20px;
            font-size: 20px;
            color: var(--theme-primary-black);
            line-height: 1.2;
          }
          .stepText {
            position: absolute;
            top: 0;
            left: 20px;
            padding: 10px 20px;
            color: white;
            display: inline-block;
            font-size: 24px;
            font-weight:bold;
            text-align: center;
            border-radius: 0 0 10px 10px;
            background-color: var(--theme-primary-suffix);
          }
        }
      }
    }
  }
  
  @media #{'only screen and (max-width: 991px)'} {
    .koutu_step {
      width: 100%;
      padding: .8rem 0;
      font-family: Arial, Arial;
      .title {
        margin: 0 auto 1rem;
        font-size: .48rem;
        color: var(--theme-primary-black);
        text-align: center;
        font-weight: 900;
      }
      .area {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: .5rem;
        margin-top: 1rem;
  
        .item {
          position: relative;
          padding: 2rem .4rem 1rem;
          background: #F6F6F6;
          border-radius: .2rem;
        //   transition: all .3s;
          overflow: visible;
          margin: 0 .3rem;
          .subtitle {
            margin-bottom: .3rem;
            font-size: .5rem;
            font-weight: bold;
            color: var(--theme-primary-suffix);
          }
          .explain {
            // margin-top: 20px;
            font-size: .3rem;
            color: var(--theme-primary-black);
            // line-height: 1.2;
          }
          .stepText {
            position: absolute;
            top: 0;
            left: .5rem;
            padding: .2rem .5rem;
            color: white;
            display: inline-block;
            font-size: .48rem;
            font-weight:bold;
            text-align: center;
            border-radius: 0 0 .2rem .2rem;
            background-color: var(--theme-primary-suffix);
          }
        }
      }
    }
  }
  </style>