<template>
  <div class="conversion-bg">
    <div class="conversion-warp">
      <div class="conversion-warp-title" v-if="content.title">
        {{ content.title }}
      </div>
      <div class="conversion-header-warp">
        <div class="conversion-header">
          <div class="conversion-title">{{ $t("conversion.original") }}:</div>
          <el-select
            v-model="originLang"
            class="select-box"
            filterable
            :placeholder="$t('conversion.placeholder')"
          >
            <el-option
              v-for="item in translateList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </div>
        <div class="conversion-header">
          <div class="conversion-title">{{ $t("conversion.title") }}:</div>
          <el-select
            v-model="contentSelect"
            multiple
            filterable
            class="select-box"
            :placeholder="$t('conversion.placeholder')"
          >
            <el-option
              v-for="item in translateList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </div>
        <div class="translate-warp translate-pc">
          <el-button
            class="translate-btn"
            @click="translate"
            type="success"
            size="mini"
            v-loading="loading"
            >{{ $t("conversion.translate") }}</el-button
          >
        </div>
      </div>
      <div class="conversion-flex">
        <div class="conversion-box">
          <el-input
            type="textarea"
            :rows="maxLength"
            size="small"
            class="input-box"
            resize="none"
            :placeholder="$t('conversion.placeholder')"
            v-model="textarea"
          >
          </el-input>
          <div class="tryBtn" v-if="!textarea" @click="trySample">
            <div class="icon" />
            {{ $t("conversion.trySample") }}
          </div>
        </div>

        <div class="translate-warp translate-h5">
          <el-button
            class="translate-btn"
            @click="translate"
            type="success"
            size="mini"
            v-loading="loading"
            >{{ $t("conversion.translate") }}</el-button
          >
        </div>
        <div class="conversion-list">
          <el-card
            class="conversion-item"
            v-for="(item, index) in languageText"
            :key="index"
          >
            <div slot="header" class="clearfix">
              <span class="conversion-item-title">{{
                showName(item.lang)
              }}</span>
              <el-button
                type="text"
                style="float: right; padding: 3px 0"
                @click="copy(item.content)"
                >{{ $t("conversion.copy") }}</el-button
              >
            </div>
            <div class="content">
              <div>{{ item.content }}</div>
              <div class="down-audio">
                <el-button
                  class="down-btn"
                  type="text"
                  :loading="downLoading"
                  @click="downLoad(item.content)"
                  >{{ $t("conversion.downAudio") }}</el-button
                >
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </div>
    <bg-active />
  </div>
</template>
<script>
import { conversion } from "@/api/pay";
import { translation, getTranslation } from "@/api";
import bgActive from "./bgActive.vue";
export default {
  components: { bgActive },
  props: {
    content: {
      type: Object,
      default: () => {
        return {
          img: "textToSpeech/banner2.jpg",
        };
      },
    },
  },
  computed: {
    maxLength() {
      const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        );
      if (isMobile) {
        return 10;
      }
      return 18;
    },
  },
  data() {
    return {
      downLoading: false,
      languageText: [],
      loading: false,
      originLang: "en",
      textarea: "",
      contentSelect: [],
      contentList: [],
      translateList: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    trySample() {
      this.textarea = this.$t("conversion.tryTips");
    },
    showName(code) {
      if (!code) {
        return "";
      }
      let item = this.translateList.find((res) => res.code === code);
      return item.name;
    },
    async downLoad(text) {
      this.downLoading = true;
      const response = await conversion({
        text: text,
        toneType: "MALE_QN_JINGYING",
      });
      // Convert array buffer to blob
      const blob = new Blob([response], { type: "audio/mpeg" });
      // Create URL for the blob
      const audioUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = audioUrl;
      link.download = "audio.mp3";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.downLoading = false;
      this.$notify.success({
        message: this.$t("conversion.downSuccess"),
      });
    },
    copy(text) {
      var textArea = document.createElement("textarea");
      // Set the text content to be copied
      textArea.value = text;
      // Append the textarea to the DOM
      document.body.appendChild(textArea);
      // Select the text in the textarea
      textArea.select();
      try {
        // Execute the copy command
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        this.$notify.success({
          message: this.$t("conversion.copySuccess"),
        });
      } catch (err) {
        console.error("Unable to copy", err);
      }

      // Remove the textarea from the DOM
      document.body.removeChild(textArea);
    },
    init() {
      getTranslation().then((res) => {
        this.translateList = res.data;
      });
    },
    translate() {
      const user_email = localStorage.getItem("user_email");
      if (!user_email) {
        document.querySelector(`#price`).scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
        return;
      }
      if (this.contentSelect.length === 0) {
        this.$notify.error({
          message: this.$t("conversion.translationSelect"),
        });
        return;
      }
      if (!this.originLang) {
        this.$notify.error({
          message: this.$t("conversion.errorLang"),
        });
        return;
      }
      this.loading = true;
      translation({
        sourceLanguage: this.originLang,
        targetLanguageList: this.contentSelect,
        text: this.textarea,
        ignoreWordList: [],
      })
        .then((res) => {
          this.loading = false;
          if (res.data && res.data.languageTextMap) {
            this.languageText = [];
            Object.keys(res.data.languageTextMap).forEach((key) => {
              try {
                this.languageText.push({
                  lang: key,
                  content: res.data.languageTextMap[key],
                });
              } catch (error) {
                console.log(error, "error>>>");
              }
            });
          } else {
            this.$notify.error({
              message: res.msg,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.conversion-bg {
  width: 100%;
  height: 100%;
  position: relative;
  // background: #8896FE;
  // background: url("@/assets/images/textToSpeech/banner2.jpg") repeat;
}
.conversion-title {
  color: #fff;
}
.banner-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  img {
    width: 100%;
    height: 100%;
  }
}
.input-box {
  ::v-deep .el-textarea__inner {
    backdrop-filter: blur(5px);
    color: #fff;
    font-size: 16px;
    background: rgba(255, 255, 255, 0.3);
  }
}
.conversion-box {
  position: relative;
}
@media (min-width: 981px) {
  .conversion-bg {
    padding: 30px 0;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
  }
  .tryBtn {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 170px;
    height: 60px;
    border-radius: 12px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    margin: auto;
    background: #1c1c1d;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    .icon {
      display: inline-block;
      background-color: currentColor;
      mask-image: url("@/assets/images/aitext/aitext.svg");
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 100% 100%;
      mask-size: 100% 100%;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      background-color: #9fb0a6;
    }
    &:hover {
      background: #2c2c2e;
      background-color: #2c2c2e;
      color: var(--theme-primary-login-btn);
    }
  }
  .conversion-header-warp {
    display: flex;
    gap: 40px;
  }
  .translate-btn {
    height: 39px !important;
  }
  ::v-deep .el-textarea__inner {
    border: none;
    border-radius: 8px 0 0 8px;
    border-right: 1px solid rgba(255, 255, 255, 0.4);
  }
  .conversion-warp {
    max-width: 1200px;
    padding: 40px;
    width: 100%;
    // background: #fff;
    border-radius: 8px;
    margin: 0 auto;
    .conversion-warp-title {
      // margin-bottom: 40px;
      font-size: 50px;
      font-weight: 900;
      line-height: 1.1;
      text-align: center;
      max-width: 1200px;
      color: #fff;
      margin: 40px 0;
    }
    .conversion-des {
      font-size: 24px;
      color: #fff;
      margin: 20px 0;
      margin-bottom: 40px;
    }
    .conversion-header {
      display: flex;
      align-items: center;
      flex: 1;
      gap: 20px;
      margin-bottom: 10px;
      .conversion-title {
        font-size: 14px;
      }
      .select-box {
        border-radius: 0;
        flex: 1;
      }
    }
    .translate-warp {
      display: flex;
      justify-content: flex-end;
    }
    .conversion-flex {
      display: flex;
      .conversion-box {
        flex: 1;
        position: relative;
      }
      .conversion-list {
        flex: 1;
        height: 412px;
        overflow-y: auto;

        backdrop-filter: blur(5px);
        background: rgba(255, 255, 255, 0.3);
        border-radius: 0px 8px 8px 0px;
        padding-top: 30px;
        &::-webkit-scrollbar {
          width: 10px;
        }

        /* Track (background) of the scrollbar */
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        /* Handle (thumb) of the scrollbar */
        &::-webkit-scrollbar-thumb {
          background: #c7d3ff;
          border-radius: 5px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #c7d3ff;
        }
        .conversion-item {
          margin: 0 30px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .down-audio {
    text-align: right;
  }
  .translate-h5 {
    display: none !important;
  }
}
@media (max-width: 980px) {
  .translate-pc {
    display: none !important;
  }
  .tryBtn {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 3rem;
    height: 1rem;
    border-radius: 0.16rem;
    font-size: 0.28rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background: #1c1c1d;
    color: #fff;
    cursor: pointer;
    .icon {
      display: inline-block;
      background-color: currentColor;
      mask-image: url("@/assets/images/aitext/aitext.svg");
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 100% 100%;
      mask-size: 100% 100%;
      width: 0.4rem;
      height: 0.4rem;
      margin-right: 0.1rem;
      background-color: #fff;
    }
  }
  .conversion-warp {
    max-width: 7rem;
    padding: 0.4rem 0;
    margin: 0 auto;
    padding-top: 1rem;
    .conversion-warp-title {
      // margin-bottom: 40px;
      font-size: 0.4rem;
      font-weight: 900;
      line-height: 1.1;
      text-align: center;
      color: #fff;
      margin: 0.4rem 0;
    }
    .conversion-des {
      font-size: 0.28rem;
      color: #fff;
      margin: 0.2rem 0;
      margin-bottom: 0.4rem;
    }
    .conversion-header {
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
      margin-bottom: 0.2rem;
      .conversion-title {
        font-size: 0.32rem;
        font-weight: bold;
      }
      .select-box {
        border-radius: 0;
        flex: 1;
      }
    }
    .translate-warp {
      display: flex;
      .translate-btn {
        margin: 0.3rem 0;
        width: 100%;
        height: 0.8rem;
        line-height: 0.6rem;
        font-size: 0.32rem;
      }
    }
    .conversion-flex {
      display: flex;
      gap: 0.3rem;
      flex-direction: column;
      .conversion-list {
        backdrop-filter: blur(5px);
        background: rgba(255, 255, 255, 0.3);
        .conversion-item {
          width: 100%;
          margin: 0 auto;
          margin-bottom: 0.2rem;
          ::v-deep .el-card__header {
          }
          .conversion-item-title {
            font-size: 0.24rem;
          }
          .clearfix {
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
          .content {
            font-size: 0.32rem;
            .down-audio {
              padding: 0.3rem 0;
              text-align: right;
            }
          }
        }
      }
    }
  }
}
</style>