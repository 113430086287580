<template>
    <!-- 首页banner 组件 -->
    <div class="banner-container">
      <div class="banner-box">
        <div class="banner-left">
          <h1 class="title">{{ content.title }}</h1>
          <p class="des"><span>{{ content.desStrong }}</span> {{ content.des }}</p>
          <a :href="$origin(content.link)"> 
            <div class="btn">{{ content.btnText }}</div>
          </a>
        </div>
        <div class="banner-right">
          <img :src="$getFile(content.img)" v-if="content.img" alt="">
          <ImageComparison :dataList="content.dataList" v-if="content.dataList" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Koutu_Banner',
    props: {
      content: {
        type: Object,
        required: true,
        default: () => { }
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
@media #{'only screen and (min-width: 992px)'} {
    .banner-container {
      overflow: hidden;
      font-family: Arial, Arial;
      width: 100%;
      background: url("@/assets/images/koutu/Banner/bg.jpg") no-repeat top center;
      background-size: cover;
      .banner-box {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row-reverse;
        // flex-direction: column-reverse;
        
        max-width: 1080px;
        margin: 0 auto;
        padding: 22px 20px 24px;
  
        .banner-left {
          width: 627px;
          height: auto;
          cursor: default;
  
          .title {
            margin-bottom: 40px;
            font-size: 70px;
            font-weight: 900;
            line-height: 1.1;
            color: var(--theme-primary-black);
          }
          .des {
            font-size: 24px;
            font-weight: bold;
            color: #4D484D;
            span {
                color: #FE9227;
            }
          }
          .btn {
            width: 200px;
            padding: 15px 0;
            text-align: center;
            color: white;
            font-size: 20px;
            font-weight: bold;
            margin-top: 40px;
            background: var(--theme-primary);
            box-shadow: 0px 5px 25px 0px rgba(0,0,0,0.2), inset 0px -3px 0px 0px #5835EC;
            border-radius: 30px;
            cursor: pointer;
          }
        }
  
        .banner-right {
          width: 464px;
          margin-right: 10px;
  
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
  
  @media #{'only screen and (max-width: 991px)'} {
    .banner-container {
      font-family: Arial, Arial;
      width: 100%;
      background: url("@/assets/images/koutu/Banner/bg.jpg") no-repeat top center;
      background-size: cover;
      .banner-box {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        padding: .5rem 0 1rem;
        .banner-left {
          padding: 0 .5rem;
          margin: 0 auto;
          text-align: center;
  
          .title {
            margin: 0 0 .2rem;
            padding: 0 .2rem;
            font-size: 0.56rem;
            color: var(--theme-primary-black);
            line-height: .72rem;
          }
  
          .des {
            margin: .4rem auto;
            font-size: .32rem;
            font-weight: bold;
            color: #4D484D;
            span {
                color: #FE9227;
            }
          }
          .btn {
            width: 200px;
            padding: 15px 0;
            text-align: center;
            color: white;
            font-size: 20px;
            font-weight: bold;
            margin-top: 40px;
            background: var(--theme-primary);
            box-shadow: 0px 5px 25px 0px rgba(0,0,0,0.2), inset 0px -3px 0px 0px #5835EC;
            border-radius: 30px;
            margin: .5rem auto 0;
            cursor: pointer;
          }

        }
  
        .banner-right {
          width: 100%;
          margin: 0 auto;
  
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
  </style>