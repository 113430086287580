import Vue from "vue";
import VueI18n from "vue-i18n";

//element 国际化处理
import locale from "element-ui/lib/locale";
import elEn from "element-ui/lib/locale/lang/en";
import elCn from "element-ui/lib/locale/lang/zh-CN";

Vue.use(VueI18n);

const i18n = new VueI18n({
 locale: "en",
 messages: {
  'en': require('./en.js'),
  'id': require('./id.json'),
 }
});
locale.i18n((key, value) => i18n.t(key, value));

export default i18n;
