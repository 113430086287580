import request from '@/utils/axios'
import { $payApiHost, $websiteApiHost } from "@/utils/index"
// 登录
export function Login(data) {
  return request({
    url: '/user/session/sign/in',
    method: 'POST',
    data,
    custom: {
      baseURL: $payApiHost()
    }
  })
}

// 注册
export function Register(data) {
  return request({
    url: '/user/session/sign/up',
    method: 'POST',
    data: {
      ...data,
      identityType: 'EMAIL'
    },
    custom: {
      baseURL: $payApiHost()
    }
  })
}

// 注销登录
export function Logout(params) {
  return request({
    url: '/user/session/sign/out',
    method: 'delete',
    params,
    custom: {
      baseURL: $payApiHost()
    }
  })
}

// 获取用户信息
export function GetUserInfo(params) {
  return request({
    url: '/user/information',
    method: 'GET',
    params,
    custom: {
      baseURL: $payApiHost()
    }
  })
}

// 修改用户信息
export function SetUserInfo(data) {
  return request({
    url: '/user/information',
    method: 'PUT',
    data,
    custom: {
      baseURL: $payApiHost()
    }
  })
}

// 取消订阅
export function Unsubscribe(data = {}) {
  return request({
    url: '/subscription/cancel',
    method: 'delete',
    data,
    custom: {
      baseURL: $payApiHost()
    }
  })
}

// 获取页面信息
export function getPageInfo(params) {
  return request({
    url: '/template/tsite?t=1',
    method: 'GET',
    params,
    custom: {
      baseURL: $websiteApiHost(), // prod
    }
  })
}

// 获取网站信息
export function getSiteInfo(params) {
  return request({
    url: '/template/tpage?t=2',
    method: 'GET',
    params,
    custom: {
      baseURL: $websiteApiHost(), // prod
    }
  })
}

// 翻译
export function translation(data) {
  return request({
    url: '/text/translation',
    method: 'POST',
    data,
    custom: {
      baseURL: $payApiHost()
    }
  })
}

// 获取翻译列表
export function getTranslation(params) {
  return request({
    url: '/text/translation/language/support',
    method: 'GET',
    params,
    custom: {
      baseURL: $payApiHost()
    }
  })
}